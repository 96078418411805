<template>
  <div>
    <b-row class="blog-list-wrapper match-height_ mt-1">
      <b-col
        v-for="blog in blogList"
        :key="blog.id"
        md="3"
      >
        <b-card
          tag="article"
          no-body
        >
          <b-link :to="{ name: 'blog-detail', params: { id: blog.id } }">
            <b-img
              :src="blog.thumbnails"
              alt="Image"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{ name: 'blog-detail', params: { id: blog.id } }"
                class="blog-title-truncate text-body-heading"
              >
                {{ blog.title }}
              </b-link>
            </b-card-title>
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center text-body">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ blog.date | formatDate }}</span>
              </div>
              <b-link
                :to="{ name: 'blog-detail', params: { id: blog.id } }"
                class="font-weight-bold"
              >
                {{ $t('home.readMore') }}
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton, BForm, BFormInput, BFormGroup, BImg, BModal, VBToggle, BSpinner, BFormSelect, BLink, BCardHeader, BCardBody,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import NavBar from '@/layouts/components/Navbar.vue'
import Ripple from 'vue-ripple-directive'
// import store from '@/store'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton, BForm, BFormInput, BFormGroup, BImg, BModal, AppTimeline, AppTimelineItem, BSpinner, BFormSelect, BLink, BCardHeader, BCardBody
    // , NavBar
    // , store
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      blogList: [],
      showOverlay: true,
    }
  },
  

  mounted() {
    this.loadNews()
  },

  methods: {
    loadNews() {
      useJwt.getNews({
      })
        .then(response => {
          const resData = response.data
          this.blogList = resData
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error getting news',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.data.email,
            },
          })
        })
        .finally(()=>{
        })
    },  
  },

  created() {
  
  },

  destroyed() {
  
  },

}
</script>

<style lang="scss" scoped>
  
</style>
